import React from "react";

import { Content } from "antd/lib/layout/layout";
import { LayoutGeneral } from "src/layout/LayoutGeneral";
import { Divider } from "antd";

import ThermoActiveImgAndIntro from "src/components/products/imgAndIntro/ThermoActive";
import ThermoActiveFeaturesListContentBlock from "src/components/products/listContentBlocks/thermoActive/Features";
import ThermoActivePropsHighligtedBlock from "src/components/products/blocks/highlighted/ThermoActiveProps";
import ThermoActiveReflectance from "src/components/products/blocks/imageWithLegend/ThermoActiveReflectance";

import ThermoActiveHeatIsland from "src/components/products/listContentBlocks/thermoActive/HeatIsland";
import ReflectiveMembraneSimpleTextBlock from "src/components/products/blocks/simpleText/ReflectiveMembrane";

import HeatIslandSolution from "src/components/products/blocks/simpleText/HeatIslandSolution";
import ThermoActiveCertificatesListContentBlock from "src/components/products/listContentBlocks/thermoActive/Certificates";
import GreenSavingsHighligtedBlock from "src/components/products/blocks/highlighted/GreenSavings";

import ThermoActiveSample from "src/components/products/blocks/imageWithLegend/ThermoActiveSample";
import ThermoActiveApplicationsListContentBlock from "src/components/products/listContentBlocks/thermoActive/Applications";
import ThermoActiveRoofHighligtedBlock from "src/components/products/blocks/highlighted/ThermoActiveRoof";

import CarouselReferencias from "src/components/referencias/CarouselCubiertas";

import ThermoActiveListPropertyCard from "src/components/products/listPropertyCards/ThermoActive";

import "src/components/products/thermoActive/style.scss";

const ThermoActive = (props) => (
  <LayoutGeneral
    {...{
      ...props,
      showPageTitle: false,
      productName: "thermoActive",
      pageTitle: "ClimateCoating ThermoActive",
      pageName: "ThermoActive",
      pageDescription:
        "ClimateCoating ThermoActive: Membrana Termocerámica con redimiento endotérmico para cubiertas y tejados",
    }}
  >
    <CarouselReferencias {...{ maxWidth: "100%", slidesToShow: 4 }} />

    <Content className="product thermoActive">
      <ThermoActiveImgAndIntro />

      <Divider className="dividerHorizontalCC" />

      <ThermoActivePropsHighligtedBlock />

      <ThermoActiveFeaturesListContentBlock />

      <div className="clearBoth" />

      <ThermoActiveReflectance />

      <ThermoActiveHeatIsland />

      <ReflectiveMembraneSimpleTextBlock />

      <HeatIslandSolution />

      <ThermoActiveCertificatesListContentBlock />

      <GreenSavingsHighligtedBlock />

      <ThermoActiveSample />

      <ThermoActiveApplicationsListContentBlock />

      <ThermoActiveRoofHighligtedBlock />

      <ThermoActiveListPropertyCard />
    </Content>
  </LayoutGeneral>
);

export default ThermoActive;
