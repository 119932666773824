import React from "react";

export const HeatIslandSolution = () => (
  <>
    <h2 className="sectionTitle leftAligned">
      Soluciones prácticas para el problema
    </h2>
    <p>
      Los productos Thermoshield® ofrecen soluciones prácticas a diversos
      problemas y eliminan, previenen y evitan de manera eficaz el moho, los
      hongos, las algas, el musgo, la suciedad y agrietamiento en las fachadas,
      así como la entrada de humedad, que puedan darse como consecuencia de
      lluvia fuerte sobre las cubiertas. La carga de calor de p. ej. cubiertas,
      interiores, contenedores, tuberías o tanques se reduce significativamente.
      Esto da como resulta- do beneficios relacionados con p. ej. tiempo,
      reducción de costes, condiciones de vida más saludables y mejor protección
      de la estructura de los edificios. Los productos Thermoshield® son en base
      agua y libres de disolventes.
    </p>
  </>
);

export default HeatIslandSolution;
