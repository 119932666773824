import React from "react";

export const ReflectiveMembraneSimpleTextBlock = () => (
  <>
    <h2 className="sectionTitle leftAligned">
      Tecnología de membrana reflectante
    </h2>

    <p>
      Lo que parece un milagro lleva siendo una realidad física desde hace
      muchos años en 135 misiones con transbordadores espaciales. Un escudo
      térmico hecho de placas cerámicas, de tan sólo unos centímetros de grosor,
      pudo resistir temperaturas superiores a 10000C al volver a entrar en la
      atmósfera terrestre, protegiendo así al transbordador y a su tripulación.
    </p>
    <p>
      El secreto está en las esferas de cerámica de vidrio especialmente
      desarrolladas que envuelven un vacío. La combinación de estas esferas
      cerámicas con un adhesivo fuerte y una dispersión especialmente diseñada,
      así como activadores, da lugar, en sentido figurado, a una “placa de
      protección térmica de cerámica líquida”, que forma una membrana
      reflectante tras la aplicación.
    </p>
    <p>
      El efecto de la tecnología de membrana reflectante se entiende mejor como
      parte de los procesos físicos que se dan de diferente manera en los
      edificios, incluyendo la reflexión, la evaporación dirigida, la
      antielectrostática y la resistencia del material. Estas propiedades
      especiales se combinan en el revestimiento para cubiertas ThermoActive y
      en otros productos bajo la marca Thermoshield®.
    </p>
  </>
);

export default ReflectiveMembraneSimpleTextBlock;
