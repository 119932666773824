import React from "react";

import { List, Card } from "antd";
import { CheckOutlined } from "@ant-design/icons";

import "src/components/products/listPropertyCards/style.scss";

export const ThermoActiveListPropertyCard = (props) => (
  <List
    {...{
      grid: {
        gutter: 16,
        xs: 1,
        sm: 2,
        md: 4,
        lg: 4,
        xl: 4,
        xxl: 4,
      },
      dataSource: [
        {
          title:
            "El revestimiento reflectante con excelente comportamiento de enfriamiento.",
          listProps: [
            "reduce significativamente el consumo de energía",
            "actúa como un sistema de aire acondicionado pasivo",
            "alarga la vida de la construcción de la cubierta",
            "reduce los costes de operación y mantenimiento",
          ],
        },
        {
          title: "Propiedades",
          listProps: [
            "reflexión de la luz solar",
            "alta y duradera resistencia a los rayos UV y resistente a agentes climatológicos",
            "elástico y cubrimiento de juntas de unión",
            "alta resistencia contra ácidos, álcalis, ozono, nitró- geno y óxidos de azufre",
          ],
        },

        {
          title: "Tonos y Colores",
          listProps: [
            "100.000 tonos disponibles",
            "Muy alta resistencia del color",
          ],
          subTitle: "Cómo Aplicarlo",
          subListProps: ["Rodillo", "Brocha", "Pulverización"],
        },
        {
          title: "Unidades de Envase",
          listProps: [
            "19,0 (= 5,02 gal)/12,5/5,0 l",
            "Consumo: 600 ml/m2 en superficies lisas, no muy absorbentes con 2 manos con geotextil embebido, hasta 1.000 ml/m2 con aplicación doble",
          ],
        },
      ],
      renderItem: (item, i) => (
        <List.Item key={i}>
          <Card className="propertyCard">
            <span className="propertyTitle">{item.title}</span>
            <ul className="propertyCardList">
              {item.listProps.map((prop, j) => (
                <li key={j}>
                  <CheckOutlined />
                  {prop}
                </li>
              ))}
            </ul>
            {item.subTitle && (
              <>
                <span className="propertyTitle">{item.subTitle}</span>
                <ul className="propertyCardList">
                  {item?.subListProps.map((prop, k) => (
                    <li key={k}>
                      <CheckOutlined />
                      {prop}
                    </li>
                  ))}
                </ul>
              </>
            )}
          </Card>
        </List.Item>
      ),
    }}
  />
);

export default ThermoActiveListPropertyCard;
