import React from "react";

import { List } from "antd";
import { CheckOutlined } from "@ant-design/icons";

import thermoactiveApplicationsRoof1 from "src/components/products/listContentBlocks/thermoActive/images/aplicaciones-thermoactive-cubierta.png";
import thermoactiveApplicationsRoof2 from "src/components/products/listContentBlocks/thermoActive/images/aplicaciones-thermoactive-cubierta-2.png";
import thermoactiveApplicationsRoof3 from "src/components/products/listContentBlocks/thermoActive/images/aplicaciones-thermoactive-cubierta-nave.png";
import thermoactiveApplicationsCustomer from "src/components/products/listContentBlocks/thermoActive/images/thermoactive-contacto-con-cliente.png";

import "src/components/products/listContentBlocks/style.scss";

export const ThermoActiveApplicationsListContentBlock = (props) => (
  <>
    <List
      {...{
        className: "listContentBlockWrapper",
        grid: {
          gutter: 40,
          xs: 1,
          sm: 2,
          md: 2,
          lg: 2,
          xl: 4,
          xxl: 4,
        },
        dataSource: [
          {
            title: "TIPOS DE CUBIERTA",
            imgSrc: thermoactiveApplicationsRoof1,
            contentListItems: [
              "cubierta plana",
              "tejado en punta",
              "cubierta inclinada",
              "para naves industriales, locales comerciales y viviendas particulares",
            ],
          },
          {
            title: "MATERIALES",
            imgSrc: thermoactiveApplicationsRoof2,
            contentListItems: [
              "metales ferrosos",
              "metales no ferrosos",
              "plásticos",
              "fibras sintéticas",
              "revestimientos viejos en buen estado",
            ],
          },
          {
            title: "ZONAS CLIMÁTICAS",
            imgSrc: thermoactiveApplicationsRoof3,
            contentListItems: [
              "clima templado cálido",
              "clima subtropical",
              "clima tropical",
              "clima templado frío",
            ],
          },
          {
            title: "VENTAJAS PARA EL CLIENTE",
            imgSrc: thermoactiveApplicationsCustomer,
            contentListItems: [
              "ahorro de energía en la refrigeración",
              "robustez de la superficie",
              "estanqueidad al agua contra la lluvia",
              "reducción de tensiones térmicas",
            ],
          },
        ],
        renderItem: (item) => (
          <List.Item className="listContentBlock imageWithList">
            <h4>{item.title}</h4>
            <img className={"blockImage "} src={item.imgSrc} />

            <ul className="applicationsList">
              {item.contentListItems.map((i) => (
                <li>
                  <CheckOutlined /> {"  "}
                  {i}
                </li>
              ))}
            </ul>
          </List.Item>
        ),
      }}
    />
  </>
);

export default ThermoActiveApplicationsListContentBlock;
