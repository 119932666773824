import React from "react";

import thermoActiveImage from "src/components/products/images/thermoActive.jpg";
import { Link } from "gatsby";
import { Row, Col, List } from "antd";
import {
  FilePptOutlined,
  FileProtectOutlined,
  SolutionOutlined,
  CheckOutlined,
  DownloadOutlined,
} from "@ant-design/icons";

import "src/components/products/imgAndIntro/style.scss";

export const ThermoActiveImgAndIntro = (props) => (
  <div className="imgAndIntro">
    <div className="productImageLinksBlock">
      <img src={thermoActiveImage} />
      <Row className="productLinks">
        <Col span="8">
          <span className="linkIcon">
            <FilePptOutlined />
          </span>
          <span className="linkText">Presentación</span>

          <span id="productPresentation" className="downloadIcon">
            <a
              href="/thermoActive/climateCoating-thermoActive-presentacion.pdf"
              download
            >
              <DownloadOutlined />
            </a>
          </span>
        </Col>

        <Col span="8">
          <Link to="/fichas-tecnicas/thermoactive/">
            <span className="linkIcon">
              <FileProtectOutlined />
            </span>
            <span className="linkText">Ficha Técnica</span>
          </Link>
          <span id="productFichaTecnica" className="downloadIcon">
            <a
              href="/thermoActive/climateCoating-thermoActive-ficha-tecnica.pdf"
              download
            >
              <DownloadOutlined />
            </a>
          </span>
        </Col>
        <Col span="8">
          <Link to="/fichas-de-aplicacion/thermoactive/">
            <span className="linkIcon">
              <SolutionOutlined />
            </span>
            <span className="linkText">Ficha de Aplicación</span>
          </Link>
          <span id="productFichaAplicacion" className="downloadIcon">
            <a
              href="/thermoActive/climateCoating-thermoActive-ficha-de-aplicacion.pdf"
              download
            >
              <DownloadOutlined />
            </a>
          </span>
        </Col>
      </Row>{" "}
    </div>
    <h1>
      <span className="paddingRight10"></span>ThermoActive
    </h1>
    <p>
      <strong>ClimateCoating ThermoActive</strong> aumenta la vida útil de la
      estructura de la cubierta y reduce la temperatura en el interior del
      edificio. Esto protege la estructura de la cubierta y reduce el consumo de
      energía de los sistemas de aire acondicionado. La innovadora fórmula en
      combinación con sus materiales de alta calidad garantizan que tras aplicar
      el revestimiento se forme una membrana flexible y reflectante con
      excelentes propiedades:
    </p>
    <ul className="productFeatureList">
      <li>
        {" "}
        <CheckOutlined />
        Muy alta reflectancia de luz solar
      </li>
      <li>
        <CheckOutlined />
        Durabilidad del material a largo plazo
      </li>
      <li>
        <CheckOutlined />
        Elasticidad y robustez superiores a la media
      </li>
      <li>
        <CheckOutlined />
        Impermeable al agua y capaz de soportar fuertes lluvias
      </li>
      <li>
        <CheckOutlined />
        Máxima resistencia
      </li>
      <li>
        <CheckOutlined />
        Resistente a temperaturas de entre-40°Cy+150°C
      </li>
    </ul>
  </div>
);

export default ThermoActiveImgAndIntro;
