import React from "react";

export const ThermoActivePropsHighligtedBlock = (props) => (
  <div
    className="hightlighted thermoActiveProps"
    style={{ marginBottom: "50px", textAlign: "center" }}
  >
    <h3>
      ThermoActive actúa como un aire acondicionado pasivo generando espacios
      más frescos bajo el calor del sol
    </h3>
  </div>
);

export default ThermoActivePropsHighligtedBlock;
