import React from "react";

import cleanImage from "src/components/products/blocks/highlighted/images/thermoActive-climatecoating-clean.png";

import "src/components/products/blocks/highlighted/style.scss";

export const GreenSavingsHighligtedBlock = (props) => (
  <div
    id="cleanGreen"
    className="greenSavings"
    style={{ marginBottom: "50px" }}
  >
    <img src={cleanImage} />
    <h3>
      Menor consumo de energía para refrigeración = reducción de emisiones de
      CO2 = contribución activa para la protección del clima.
    </h3>
    <div className="clearer" />
  </div>
);

export default GreenSavingsHighligtedBlock;
