import React from "react";

import { List, Divider } from "antd";

import containerTestsThermoActiveImage from "src/components/products/listContentBlocks/thermoActive/images/containerTests-ThermoActive.jpg";

import twoContainersThermoActiveImage from "src/components/products/listContentBlocks/thermoActive/images/twoContainers-ThermoActive.jpg";

import "src/components/products/listContentBlocks/style.scss";

export const ThermoActiveFeaturesListContentBlock = (props) => (
  <List
    {...{
      className: "listContentBlockWrapper",
      grid: {
        gutter: 40,
        xs: 1,
        sm: 2,
        md: 2,
        lg: 2,
        xl: 2,
        xxl: 2,
      },
      dataSource: [
        {
          imgSrc: twoContainersThermoActiveImage,
          imageLegend:
            "Comparativa entre 2 contenedores uno de ellos con ThermoActive",
          title1: <h4>Altos valores de reflectancia – casi como la nieve</h4>,
          content1: (
            <p>
              El revestimiento para cubiertas ThermoActive puede reflejar más
              del 91% de la luz solar. Este valor máximo ha sido probado. La
              nieve pura refleja el 100% de la luz solar.
            </p>
          ),

          title2: (
            <h4>Reducción de costes mediante aire acondicionado pasivo</h4>
          ),
          content2: (
            <p>
              El revestimiento reflectante ThermoActive absorbe la humedad, que
              al evaporarse cuando se expone a la luz solar produce un
              enfriamiento. Esto funciona incluso cuando la cubierta no está
              revestida en blanco. Se consigue un efecto de enfriamiento
              relativamente alto mediante la evaporación incluso con colores más
              oscuros. Esto conduce a una reducción del consumo de energía
              relacionado con la refrigeración y, en con secuencia, a una
              disminución de las emisiones de CO2.
            </p>
          ),
        },
        {
          imgSrc: containerTestsThermoActiveImage,
          imageLegend: "Contenedor revestido con ThermoActive",
          title1: <h4>Mayor vida útil para la cubierta</h4>,
          content1: (
            <>
              <p>
                La excelente elasticidad de ThermoActive permite cubrir sin
                problemas las juntas entre los elementos de revestimiento de la
                cubierta, y eso a temperaturas desde -40°C hasta +150°C. La
                cubierta permanece impermeable al agua.
              </p>
              <p>
                El revestimiento es extremadamente robusto y puede resistir la
                acción de agentes medioambientales como ácidos, álcalis, ozono,
                óxidos de nitrógeno y azufre. El revestimiento también es
                extremadamente resistente a los rayos UV y tiene una excelente
                estabilidad a lar- go plazo. El revestimiento no se fractura ni
                se escama.
              </p>
              <p>
                Todo esto prolonga la vida útil de la construcción de la
                cubierta y reduce los costes de mantenimiento.
              </p>
              ,
            </>
          ),
        },
      ],
      renderItem: (item) => (
        <List.Item className="listContentBlock">
          <img className="blockImage" src={item.imgSrc} />
          <span className="contentBlockImgLegend">{item.imageLegend}</span>
          <Divider className="dividerHorizontalCC" />
          {item.content1 && (
            <>
              {item.title1}
              {item.content1}
            </>
          )}
          {item.content2 && (
            <>
              {item.title2}
              {item.content2}
            </>
          )}
        </List.Item>
      ),
    }}
  />
);

export default ThermoActiveFeaturesListContentBlock;
