import React from "react";

import thermoActiveReflectanciaImage from "src/components/products/blocks/imageWithLegend/images/thermoActive-reflectancia.jpg";

import "src/components/products/blocks/imageWithLegend/style.scss";

export const ThermoActiveReflectance = (props) => (
  <>
    <div className="blockImageWithLegend">
      <img className="imageOfBlock" src={thermoActiveReflectanciaImage} />
      <span className="imageLegend">
        Revestimientos reflectantes para cubiertas con efecto significativo. El
        SRI se ha calculado de acuerdo al estándar Americano (ASTM). Los valores
        por encima de 110 se consideran valores máximos.
      </span>
    </div>
    <div className="clearBoth" />
  </>
);

export default ThermoActiveReflectance;
