import React from "react";

import { List, Divider } from "antd";

import certificatesGreenguardEfficiency from "src/components/products/listContentBlocks/thermoActive/images/certificados-greenguard-energystar.jpg";

import certificatesGermanSingapore from "src/components/products/listContentBlocks/thermoActive/images/certificados-german-innovation-singapore-efficiency.jpg";

import "src/components/products/listContentBlocks/style.scss";

export const ThermoActiveCertificatesListContentBlock = (props) => (
  <>
    <List
      {...{
        className: "listContentBlockWrapper",
        grid: {
          gutter: 40,
          xs: 1,
          sm: 2,
          md: 2,
          lg: 2,
          xl: 2,
          xxl: 2,
        },
        dataSource: [
          {
            imgClassName: "marginTop40",
            imgSrc: certificatesGreenguardEfficiency,
            imageLegend: "Certificaciones Energy Star y Greenguard",
            content: (
              <p>
                Los productos, que se basan en la tecnología única de membrana
                reflectante, ayudan a ahorrar material, energía y recursos
                humanos, protegen las costosas estructuras de los edificios de
                una manera sostenible, preservan los activos para futuras gene-
                raciones y protegen el medio ambiente.
              </p>
            ),
          },
          {
            imgClassName: "",
            imgSrc: certificatesGermanSingapore,
            imageLegend:
              "German Innovation Award 2018 – Singapore Energy Efficiency Award 2018",
            content: (
              <p>
                ClimateCoating ThermoActive cumple con las estrictas pautas de
                eficiencia energética de EnergyStar. En uno de los estándares
                más estrictos del mundo relacionado con las emisiones químicas,
                ThermoActive recibió el certificado Greenguard “Gold”.
              </p>
            ),
          },
        ],
        renderItem: (item) => (
          <List.Item className="listContentBlock">
            <img
              className={"blockImage " + item.imgClassName}
              src={item.imgSrc}
            />
            <span className="contentBlockImgLegend">{item.imageLegend}</span>
            <Divider className="dividerHorizontalCC" />
            <>{item.content}</>
          </List.Item>
        ),
      }}
    />
  </>
);

export default ThermoActiveCertificatesListContentBlock;
