import React from "react";

import thermoActiveReflectanciaImage from "src/components/products/blocks/imageWithLegend/images/ejemplo-de-aplicacion-de-thermoActive.png";

import "src/components/products/blocks/imageWithLegend/style.scss";

export const ThermoActiveSample = (props) => (
  <>
    <div className="blockImageWithLegend">
      <img className="imageOfBlock" src={thermoActiveReflectanciaImage} />
      <span className="imageLegend wideSingleImg">
        Problema: Sobrecalentamiento de las naves de producción debido al
        calentamiento de la cubierta (14.000 m2). Solución: revestimiento con
        tecnología de membrana reflectante, ThermoActive en blanco. Temperatura
        de la cubierta exterior: antes de 51,4°C | después de 28,2°C
      </span>
    </div>
    <div className="clearBoth" />
  </>
);

export default ThermoActiveSample;
