import React from "react";

export const ThermoActiveRoofHighligtedBlock = (props) => (
  <div
    className="hightlighted thermoActiveRoof"
    style={{ marginBottom: "50px", textAlign: "center" }}
  >
    <h3>
      ThermoActive – el revestimiento para cubiertas eficaz y resistente al agua
      que mantiene los espacios frescos bajo el calor del sol. Estaremos
      encantados de ayudarle a resolver su problema.
    </h3>
  </div>
);

export default ThermoActiveRoofHighligtedBlock;
