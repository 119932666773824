import React from "react";

import { Link } from "gatsby";
import Slider from "react-slick";

import { PlusCircleOutlined, LikeOutlined } from "@ant-design/icons";

import revenga from "src/components/referencias/images/cubiertas/climateCoating-thermoActive-cubierta-oficinas-centrales-grupo-revenga.jpg";
import faunia from "src/components/referencias/images/cubiertas/climateCoating-thermoActive-cubierta-faunia-madrid.jpg";
import tejadoNorteMadrid from "src/components/referencias/images/cubiertas/climateCoating-thermoActive-tejado-vivienda-norte-madrid.jpg";

import { Card } from "antd";

const { Meta } = Card;

export const ReferenciasCarousel = ({
  float = "none",
  width = "1600px",
  maxWidth = "98%",
  maxHeight = "100px",
  marginLeft = "auto",
  marginRight = "auto",
  slidesToShow = 4,
  marginTop = "50px",
  marginBottom = "50px",
}) => {
  var settings = {
    dots: true,
    speed: 500,
    slidesToScroll: 1,
    slidesToShow,
    rows: 1,
    infinite: false, //items.length > 3,
    responsive: [
      {
        breakpoint: 750,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const imgWith = 120;

  return (
    <div
      className="carouselReferencias"
      style={{
        width,
        float,
        maxWidth,
        marginBottom,
        maxHeight,
        marginRight,
        marginLeft,
        marginTop,
        marginBottom,
      }}
    >
      <Slider {...settings}>
        <div key="icono">
          <div className="refItem">
            <div className="referenceIcon thermoActive colored">
              <LikeOutlined />
            </div>
            <div className="refText greyed icon">
              <span className="textRefTitle ">
                ClimateCoating para Cubiertas
              </span>
              <span className="textRefSubTitle">
                Selección de proyectos en España
              </span>
              <Link to={"/referencias/cubiertas/"}>
                <PlusCircleOutlined /> Ir a Referenicas
              </Link>
            </div>
            <div className="clearer height0"></div>
          </div>
        </div>
        <div key="revenga">
          <div className="refItem">
            <img style={{ width: imgWith }} alt="example" src={revenga} />
            <div className="refText">
              <span className="textRefTitle thermoActive colored">
                Aplicación en Tela Asfáltica
              </span>
              <span className="textRefSubTitle">Tres Cantos - Madrid</span>
              <Link to={"/referencias-cubiertas/cubierta-sede-grupo-revenga/"}>
                <PlusCircleOutlined /> Más Info
              </Link>
            </div>
            <div className="clearer height0"></div>
          </div>
        </div>
        <div key="laMoraleja">
          <div className="refItem">
            <img
              style={{ width: imgWith }}
              alt="example"
              src={tejadoNorteMadrid}
            />
            <div className="refText">
              <span className="textRefTitle thermoActive colored">
                Tejado en Adosado
              </span>
              <span className="textRefSubTitle">Afueras de Madrid - A6</span>
            </div>
            <div className="clearer height0"></div>
          </div>
        </div>
        <div key="faunia">
          <div className="refItem">
            <img style={{ width: imgWith }} alt="example" src={faunia} />
            <div className="refText">
              <span className="textRefTitle thermoActive colored">
                Cubierta y carpa flexible
              </span>
              <span className="textRefSubTitle">Parque Faunia - Madrid</span>
              <Link to={"/referencias-cubiertas/carpa-cubierta-faunia/"}>
                <PlusCircleOutlined /> Más Info
              </Link>
            </div>
            <div className="clearer height0"></div>
          </div>
        </div>
        {/*         <div key="sotogrande">
          <div className="refItem">
            <img style={{ width: imgWith }} alt="example" src={sotogrande} />
            <div className="refText">
              <span className="textRefTitle thermoActive colored">
                Unifamiliar en la Sotogrande
              </span>
              <span className="textRefSubTitle">Sogrande - Cádiz</span>
            </div>
            <div className="clearer height0"></div>
          </div>
    
          </div>
    */}
      </Slider>
    </div>
  );
};

export default ReferenciasCarousel;
