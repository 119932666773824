import React from "react";

import { List, Divider } from "antd";

import containerTestsThermoActiveImage from "src/components/products/listContentBlocks/thermoActive/images/containerTests-ThermoActive.jpg";

import certificatesThermoActive from "src/components/products/listContentBlocks/thermoActive/images/certificados-thermoactive.jpg";

import { CheckOutlined } from "@ant-design/icons";

import "src/components/products/listContentBlocks/style.scss";

export const ThermoActiveFeaturesListContentBlock = (props) => (
  <>
    {" "}
    <h2 className="sectionTitle"> El problema del calentamiento</h2>
    <List
      {...{
        className: "listContentBlockWrapper",
        grid: {
          gutter: 40,
          xs: 1,
          sm: 2,
          md: 2,
          lg: 2,
          xl: 2,
          xxl: 2,
        },
        dataSource: [
          {
            title: <h3>El efecto de isla de calor urbana</h3>,
            content: (
              <>
                <p>
                  Las ciudades tienen su propio clima, que se ve influido por el
                  desarrollo urbano y las emisiones. La temperatura en la ciudad
                  es generalmente más alta que en las zonas rurales. La
                  diferencia puede ser de hasta 10°C. La temperatura se ve
                  afectada, entre otras co- sas, por las propiedades térmicas de
                  los materiales de construcción y las propiedades de radiación
                  de sus superficies. Otros factores de influencia incluyen la
                  localización geográfica de la ciudad, su geometría y la
                  distribución de sus edificios, así como su tráfico, la
                  industria y el comportamiento de sus habitantes. Esto hace que
                  se cree un efecto de isla de calor que impacta en la salud
                  humana y en la naturaleza:
                </p>
                <ul className="productFeatureList">
                  <li>
                    <CheckOutlined />
                    Estrés térmico en el cuerpo humano
                  </li>
                  <li>
                    <CheckOutlined /> Mayor consumo de energía de los sistemas
                    de aire acondicionado
                  </li>
                  <li>
                    <CheckOutlined />
                    Mayor número de días cálidos y noches calurosas
                  </li>{" "}
                </ul>
                <p>
                  Los sistemas de aire acondicionado emiten aire caliente al
                  ambiente durante el proceso de refrigeración y consumen
                  energía eléctrica. La Agencia Internacional de Energía (iea,
                  Francia) estima que el consumo de energía de los sistemas de
                  aire acondicionado se habrá triplicado en todo el mundo para
                  el año 2050.
                </p>
              </>
            ),
          },
          {
            title: <h3>Reflexión como parte de la solución</h3>,
            content: (
              <>
                <p>
                  Parte de la solución es evitar que las cubiertas se calienten
                  maximizando la reflectancia de la radiación solar. El
                  innovador revestimiento de cubiertas Tops- hield ha sido
                  desarrollado precisamente con este propósito. Refleja el 91,4%
                  de la luz solar (ver tabla superior). La tecnología de
                  membrana reflectante de ThermoActive proporciona un
                  enfriamiento adicional de la cubierta a través de un proceso
                  de evaporación.
                </p>
                <div className="imgWrapper">
                  <img src={certificatesThermoActive} />
                  <span className="imageLegend">
                    R&D Services, Inc, Cookeville, TS (acreditado por IAS),
                    Informe inicial de resultados de las pruebas CRRC*, Marzo de
                    2014 (CRRC = Cool Roof Rating Council). Informe de Prueba
                    IMPiB, Departamento de Pinturas y Plásticos, Investigación y
                    Análisis en Gliwice/ Polonia, junio de 2015.
                  </span>
                </div>
                ,
              </>
            ),
          },
        ],
        renderItem: (item) => (
          <List.Item className="listContentBlock">
            <img className="blockImage" src={item.imgSrc} />
            <span className="contentBlockImgLegend">{item.imageLegend}</span>

            <>
              {item.title}
              {item.content}
            </>
          </List.Item>
        ),
      }}
    />
  </>
);

export default ThermoActiveFeaturesListContentBlock;
